//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    BlogPostCard: () => import('@/containers/homepage/BlogPostCard.vue'),
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blogPosts: [],
    }
  },
  computed: {
    postCardStyleInstagramLink() {
      return this.$t('homePage.postCardStyleInstagramLink')
    },
  },
  mounted() {
    this.$axios.$get('blogs/home').then((response) => {
      this.blogPosts = response.data.map((post) => ({
        id: post.id,
        title: post.title,
        alt: post.title,
        image: post.media,
        to: post.slug,
      }))
    })
  },
}
